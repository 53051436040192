import { FC } from 'react'
import Picker from 'react-mobile-picker-scroll'
import { PickerContainer } from './styled'

type TimePickerProps = {
  hours: number
  minutes: number
  //
  hoursStart: number
  hoursEnd: number
  minutesStart?: number
  minutesEnd?: number
  //
  handleTime: (name: string, value: number) => void
}

export const TimePicker: FC<TimePickerProps> = ({
  hours,
  minutes,
  //
  hoursStart,
  hoursEnd,
  minutesStart = 0,
  minutesEnd = 45,
  //
  handleTime,
}) => {
  const getRangedArray = (start = 0, end = 59, multiplier = 1) => {
    const res = []
    for (let i = start; i <= end; i += multiplier) {
      res.push(i)
    }
    return res
  }

  return (
    <PickerContainer>
      <Picker
        optionGroups={{
          hours: getRangedArray(hoursStart, hoursEnd),
          minutes: getRangedArray(minutesStart, minutesEnd, 15),
        }}
        valueGroups={{
          hours,
          minutes,
        }}
        onChange={handleTime}
        itemHeight={25}
        height={150}
      />
    </PickerContainer>
  )
}
