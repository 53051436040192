import { FC, useEffect, useState } from 'react'
import moment from 'moment'
import { Button } from '../../components/Button'
import { Select } from '../../components/Select'
import { Spinner } from '../../components/Spinner'
import { RenderIf } from '../../components/RenderIf'
import { useTranslations } from '../../hooks/useTranslations'
import { handleLocalStorage } from '../../utils/handleLocalStorage'
import { useMySelector } from '../../hooks/useMySelector'
import { Calendar } from '../../components/Calendar'
import { SelectContainer } from './styledComponents'
import { SelectTime } from './styled'

type TimePickerProps = {
  increaseStep: () => void
}

export const TimePicker: FC<TimePickerProps> = ({ increaseStep }) => {
  // Hooks
  const t = useTranslations()

  const { isAppInit } = useMySelector((state) => state.app)
  const { LeadTimeDays, LeadTimeHours } = useMySelector(
    (state) => state.app.options
  )

  // State
  const [dateTime, setDateTime] = useState(moment())
  const [selectedValue, setSelectedValue] = useState(
    getOptions(t, LeadTimeDays)[0].value
  )

  // useEffects
  useEffect(() => {
    setSelectedValue(getOptions(t, LeadTimeDays)[0].value)
  }, [LeadTimeDays])

  // Functions
  const onChange = (value: string) => {
    setSelectedValue(value)
  }

  const handleAccept = () => {
    if (selectedValue !== '-1') {
      handleLocalStorage({ pickUpDelay: selectedValue })
    } else {
      handleLocalStorage({
        pickUpTime: dateTime.utc().format(),
      })
    }
    increaseStep()
  }

  const getMinDateGap = () => {
    const timeNow = moment().format('HH:mm')
    const timeLead = moment(LeadTimeHours, 'HH:mm').format('HH:mm')
    if (timeNow > timeLead) {
      return LeadTimeDays + 1
    }
    return LeadTimeDays
  }

  return (
    <SelectContainer>
      <SelectTime>{t('clickCollect.choosePickUpTime')}</SelectTime>
      <RenderIf condition={isAppInit}>
        <RenderIf condition={LeadTimeDays === 0}>
          <p>{t('clickCollect.time')}</p>
          <Select
            options={getOptions(t, LeadTimeDays)}
            defaultValue={selectedValue}
            onChange={onChange}
          />
        </RenderIf>
        <RenderIf condition={selectedValue === '-1'}>
          <Calendar
            showTime
            minDateGap={getMinDateGap()}
            onUpdate={setDateTime}
          />
        </RenderIf>
        <Button buttonType="common" width="100%" onClick={handleAccept}>
          {t('buttons.accept')}
        </Button>
      </RenderIf>
      <RenderIf condition={!isAppInit}>
        <Spinner type="alternate" />
      </RenderIf>
    </SelectContainer>
  )
}

const getOptions = (
  t: (value: string, substitutions?: any) => string,
  LeadTimeDays: number
) => {
  return [
    ...(LeadTimeDays === 0
      ? [
          { value: '0', label: t('clickCollect.asap') },
          { value: '15', label: t('clickCollect.minutes', { value: 15 }) },
          { value: '30', label: t('clickCollect.minutes', { value: 30 }) },
          { value: '60', label: t('clickCollect.minutes', { value: 60 }) },
        ]
      : []),
    { value: '-1', label: t('clickCollect.individual') },
  ]
}

export default TimePicker
