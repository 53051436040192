import { FC, useEffect, useState } from 'react'
import moment, { Moment } from 'moment'

import arrowLeft from '../../assets/common/arrowLeft.svg'
import arrowRight from '../../assets/common/arrowRight.svg'
import { CalendarContainer, Date, MonthHeader, WeekButton } from './styled'

type WeekHandlerProps = {
  value: Moment
  onChange?: (newValue: Moment) => void
  minDate?: Moment
  minDateGap?: number
}

export const WeekHandler: FC<WeekHandlerProps> = ({
  value,
  onChange,
  minDate = moment(),
}) => {
  const [selectedDate, setSelectedDate] = useState(moment())
  const [weekStart, setWeekStart] = useState(moment(value).startOf('isoWeek'))

  useEffect(() => {
    setSelectedDate(moment(value).startOf('day'))
  }, [value])

  const subtractWeek = () => {
    const isAvailable = minDate.diff(weekStart, 'days') < 0
    if (isAvailable) {
      setWeekStart((prevValue) => moment(prevValue.subtract(1, 'week')))
    }
  }

  const addWeek = () => {
    setWeekStart((prevValue) => moment(prevValue.add(1, 'week')))
  }

  const setNewDate = (newValue: Moment) => {
    const isLaterThanLastAvailable = minDate.diff(newValue, 'days') < 1
    if (isLaterThanLastAvailable) {
      if (onChange) {
        onChange(newValue)
      } else {
        setSelectedDate(newValue)
      }
    }
  }

  return (
    <div>
      <MonthHeader>
        {moment(weekStart).add(3, 'days').format('MMMM')}
      </MonthHeader>
      <CalendarContainer>
        <WeekButton
          onClick={subtractWeek}
          isDisabled={minDate.diff(weekStart, 'days') > -1}
        >
          <img src={arrowLeft} alt="Prev" />
        </WeekButton>
        {[...Array(7)].map((_el, i) => {
          const value = moment(weekStart).add(i, 'days')
          return (
            <Date
              key={`calendarDate_${value.format('DD')}_${i}`}
              isSelected={!selectedDate.diff(value, 'days')}
              isDisabled={minDate.diff(value, 'days') > 0}
              onClick={() => setNewDate(value)}
            >
              <p>{value.format('ddd')}</p>
              <p>{value.format('DD')}</p>
            </Date>
          )
        })}
        <WeekButton onClick={addWeek}>
          <img src={arrowRight} alt="Next" />
        </WeekButton>
      </CalendarContainer>
    </div>
  )
}
